import React from "react"
import { Link } from "gatsby"

const Footer = () => {

    return (
        <footer id="footer" className="sticky-footer dark">

            <div id="copyrights">
                <div className="container">

                    <div className="row align-items-center justify-content-between col-mb-30">
                        <div className="col-lg-auto text-center text-lg-left">
                            Copyrights &copy; 2004 VarliktanVeriler.com<br />
                            <div className="copyright-links"><Link to='#'>Kullanım Şartları</Link> / <Link to='#'>Gizlilik Politikası</Link></div>
                        </div>

                        <div className="col-lg-auto text-center text-lg-left">
                            <div className="copyrights-menu copyright-links m-0">
                                <Link to="/en">Home</Link> /
                                <Link to="/en/the-inspirations-from-the-existence"><div>The Inspirations From The Existence</div></Link> /
                                <Link to="/en/books">Books</Link> /
                                <Link to="/iletisim">Contact</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    )
}


export default Footer
