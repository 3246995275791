import React from "react"
import Header from "./header.en"
import Footer from "./footer.en"

export default function LayoutEn({children}) {
    return (
        <div id="wrapper">
            <Header/>
            {children}
            <Footer/>
        </div>
    )
}
