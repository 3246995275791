import React from "react"
import {graphql, Link} from 'gatsby';
import LayoutEn from '../../components/layout.en';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../../hooks/use-site-metadata';

const KitaplarPageEn = ({data, location}) => {
    const posts = data.allMarkdownRemark.nodes
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (
        <LayoutEn>
            <GatsbySeo
                title="Books"
                description={"In these books, the truth of \"Allah, the Universe (universe) and Man\", each subject related to Sufism (metaphysics) in detail; You can find other religious and Islamic-philosophical-theological-fiqh topics, albeit partially detailed and partially summary."}
                language="en"
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: "Books of Kazım Yardımcı",
                    locale: "en",
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">
                <div className="container">
                    <h1>Books</h1>
                    <span>Kazım Yardımcı's books</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <div id="portfolio" className="portfolio row grid-container gutter-20" data-layout="fitRows">
                            {posts.map(book => {
                                return (
                                    <article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12" key={book.frontmatter.slug}>
                                        <div className="grid-inner">
                                            <div className="portfolio-image">
                                                <Link to={book.frontmatter.slug}><img src={book.frontmatter.featuredImage?.childImageSharp.fluid.src} style={{maxHeight:'386px', borderRadius: '3px'}} alt={`${book.frontmatter.title} - ${book.frontmatter.author}`}/></Link>
                                            </div>
                                            <div className="portfolio-desc">
                                                <h3><Link to={book.frontmatter.slug}>{book.frontmatter.title}</Link></h3>
                                                <span>{`${book.frontmatter.author} - ${book.frontmatter.year}`}</span>
                                            </div>
                                        </div>
                                    </article>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="container clearfix topmargin-sm bottommargin-lg">
                    <div className="line line-sm"></div>

                    <p>The work called “Bütün Yönleriyle Tassavvuf ve Rufai Külliyatı” contains the Author Kazım Yardımcı’s seven books in Turkish – Varlık, İslam’da Mezhepler ve Yükseliş, Günahsızlar, Muhammed-İsa-Adem, İnsanda Yükselme, İslamda Şeriat ve Tarikat, İslam Ekonomisi- and his articles. In this work, you can find the reality of Allah, of the Universe (Cosmos) and of Human in addition to all the matters concerning Sufism (metaphysics) in detail. You can also read other religious and Islamic matters -philosophical, scholastic and canonical- partly in details, partly as a summary, but as an answer to your difficulties and questions.</p>
                    <p>This work presents an encyclopedic characteristic, yet, you will see that it contains a satisfactory content on the subjects that you cannot find in an encyclopedia. These books and articles are not written for commercial purposes. All the books and articles belonging to the author are entirely loaded on internet and presented to humanity. You can find these books and articles in our website and read here. You are also allowed to download them to your own computer or have them written as a fascicle free from www.varliktanveriler.com. No writer has presented all his books and articles to humanity through internet yet. This is a difference special to our author as he does not expect any material benefit or advantage from this. He has done this for the consent of Allah and his love for humanity. THIS MAKES A DIFFERENCE. We hope that you will also notice this distinction of our author.</p>
                    <p>In addition, he has not attached any price tag on this Rufai Collection in four volumes. Our author sends them to individuals as a gift after signing his books. Our author has no commercial or financial purposes because he says, “the Religion of Allah is very sublime and blessed (it is very lofty and holy) but material things and money is very low. What is sublime and holy cannot be changed with money or material things. He who sells and makes his living on the Koran, Hadiths and religious books encounters a very severe punishment in the presence of Allah.</p>
                    <p>With regards…</p>
                </div>

            </section>
        </LayoutEn>
    )
}

export default KitaplarPageEn

export const pageQuery = graphql`
  query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(/content/books/)/"  }}
          sort: { fields: [frontmatter___no], order: ASC }
      ) {
      nodes {
        frontmatter {
          title
          slug
          year
          author
          featuredImage {
            childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
  }
`